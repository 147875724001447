.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  overflow: hidden;
}
.inputArea {
  background: none; 
  border: none; 
  color: inherit; 
  flex-grow: 1; 
  font-family: inherit; 
  font-size: inherit; 
  padding: 0px;
  width: -webkit-fill-available;
  max-width: calc(100% - 10em);
}
.inputPrompt {
  margin-right: 5px; 
  color: rgb(189, 8, 28);
}
input:focus { 
  outline: none; 
}
.output { 
  display: block; 
  color: white;
}
.App {
  text-align: left !important; 
  line-height: 1.5em; 
  padding: 10px; 
  background: black;
  color: green;
  min-height: 100vh;
  min-width: 100vw;
}
